import {
  Alert, AvatarGroup, Box, Divider, Link, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import CabinetPage from "../../components/Common/CabinetPage";
import colors from "../../colors";
import { useMemo, useState } from "react";
import CabAvatar from "@CabComponents/CabAvatar";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoAdd, IoCalendarOutline, IoClose, IoCloseCircleOutline, IoPeopleOutline } from "react-icons/io5";
import { ContactDenorm, ContactOrgDenorm, Leader } from "../../store";
import { CabTabs } from "@CabComponents/CabTabs";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  AppCrmContactNotesPartialUpdateApiArg,
  AppCrmContactTasksPartialUpdateApiArg,
  Contact, ContactCategory, ContactFieldTemplate, ContactNote, ContactTask, ContactType
} from "../../store/cabinetApi/generated/crm";
import CabSpinner from "@CabComponents/CabSpinner";
import { FormController } from "../../utils/formUtils";
import { CabModal } from "@CabComponents/CabModal";
import { Trigger } from "../../store/utils";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { AnalyticsEvent } from "../../store/cabinetApi/generated/analytics";
import { DateTime } from "luxon";
import { BasicInfoListItem, Widget, WidgetList, WidgetListItem } from "../../components/CRM/SharedCrmComponents";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import { CabExecPicker } from "@CabComponents/CabExecPicker";
import CabRTEditor from "@CabComponents/CabRTEditor";
import BackButton from "@CabComponents/CabBackButton";


const tabs = [
  { label: 'Activities' },
  { label: 'Reminders' },
  { label: 'Notes' },
  { label: 'Meetings' },
];


// eslint-disable-next-line max-len
const EMPTY_STATE = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';


const isRT = (text: string) => {
  try {
    JSON.parse(text);
  } catch (err) {
    return false;
  }
  return true;
};

const CRMContactView = ({
  contact, orgOptions, categoryOptions, typeOptions, fieldTemplates,
  onNavigateToContacts, onNavigateToDataView, onSaveBasicInfo, loading, loadingEvents, contactOrgPage,
  tasks, notes, events, onAddNote, onUpdateNote, onDeleteNote, onAddTask, onUpdateTask, onDeleteTask, onSetOrgString,
  relationshipLeaders, leaderOptions, onUpdateRelationships,
}: {
  contact: ContactDenorm | null;
  onNavigateToContacts: () => void;
  onNavigateToDataView: (category: string) => void;
  onSaveBasicInfo: (info: Partial<Contact>) => void;
  orgOptions: ContactOrgDenorm[];
  categoryOptions: ContactCategory[];
  typeOptions: ContactType[];
  fieldTemplates: ContactFieldTemplate[];
  loading: boolean;
  tasks: ContactTask[];
  notes: ContactNote[];
  events: AnalyticsEvent[];
  leaderOptions: Leader[];
  relationshipLeaders: Leader[];
  loadingEvents: boolean;
  contactOrgPage?: string;
  onAddNote: Trigger<ContactNote, ContactNote>;
  onUpdateNote: Trigger<AppCrmContactNotesPartialUpdateApiArg, ContactNote>;
  onDeleteNote: Trigger<ContactNote['id'], unknown>;
  onAddTask: (t: { title: string }) => Promise<void>;
  onUpdateTask: Trigger<AppCrmContactTasksPartialUpdateApiArg, ContactTask>;
  onDeleteTask: Trigger<ContactTask['id'], unknown>;
  onSetOrgString: (val: string) => void;
  onUpdateRelationships: (leaderRelationships: Leader['id'][]) => Promise<void>;
}) => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [editNote, setEditNote] = useState<ContactNote['id'] | null>(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [editTask, setEditTask] = useState<ContactTask['id'] | null>(null);
  const [showRelationshipsModal, setShowRelationshipsModal] = useState(false);

  const activities = useMemo(() => (
    [...tasks, ...notes, ...events]
      .map(a => ({
        ...a,
        sortDate: 'assignee' in a ? a.created_at
          : 'body' in a ? a.updated_at
            : 'event_idr' in a ? a.start_date
              : undefined
      }))
      .toSorted((a, b) => {
        const sortDateA = a.sortDate != null ? DateTime.fromISO(a.sortDate) : undefined;
        const sortDateB = b.sortDate != null ? DateTime.fromISO(b.sortDate) : undefined;
        if (!sortDateA) return 1;
        if (!sortDateB) return -1;

        return sortDateB.toMillis() - sortDateA.toMillis();
      })
  ), [tasks, notes, events]);

  const handleAddEditNote = async ({ body }: { body: string }) => {
    if (!contact) return;

    if (editNote != null) {
      await onUpdateNote({ id: editNote, patchedContactNote: { body } });
      setEditNote(null);
    } else {
      await onAddNote({
        id: -1,
        body: body,
        updated_at: '',
        body_plain_text: '',
        contacts: [contact.id],
        teammates: [],
        company: [],
        allowed_users: [],
      });
    }

    setShowNoteModal(false);
  };

  const handleAddEditNoteCancel = () => {
    setEditNote(null);
    setShowNoteModal(false);
  };

  const handleClickNote = (id: ContactNote['id']) => {
    setEditNote(id);
    setShowNoteModal(true);
  };

  const handleAddEditTask = async ({ title }: { title: string }) => {
    if (editTask != null) {
      await onUpdateTask({ id: editTask, patchedContactTask: { title } });
      setEditTask(null);
    } else {
      await onAddTask({
        title: title,
      });
    }

    setShowTaskModal(false);
  };

  const handleAddEditTaskCancel = () => {
    setEditTask(null);
    setShowTaskModal(false);
  };

  const handleClickTask = (id: ContactTask['id']) => {
    setEditTask(id);
    setShowTaskModal(true);
  };

  const handleClickActivity = (id: ContactTask['id'] | ContactNote['id'], activityType: 'task' | 'note') => {
    if (activityType === 'task') {
      handleClickTask(id);
    } else {
      handleClickNote(id);
    }
  };

  const handleDeleteActivity = (id: ContactTask['id'] | ContactNote['id'], activityType: 'task' | 'note') => {
    if (activityType === 'task') {
      onDeleteTask(id);
    } else {
      onDeleteNote(id);
    }
  };

  const handleUpdateRelationships = async ({ leaderRelationships }: { leaderRelationships: Pick<Leader, 'id'>[] }) => {
    await onUpdateRelationships(leaderRelationships.map(l => l.id));
    setShowRelationshipsModal(false);
  };

  return (
    <CabinetPage
      pageName="Contact"
      headerContent={
        <Box width='100%' display='flex' justifyContent='space-between'>
          <BackButton />
          <CabCollapseMenu
            popOverSx={theme => ({ marginTop: theme.spacing(0.5), width: 500 })}
            target={
              <CabButton
                buttonType="primary"
                icon={<CabIcon Icon={IoAdd} alt=''/>}
              >
                Create
              </CabButton>
            }
            buttons={
              <Box width={260}>
                <CabButton 
                  buttonType="text"
                  onClick={() => setShowTaskModal(true)}
                  sx={{ width: '100%', '& .button-text': { width: '100%', color: colors.black900, textAlign: 'left' } }}
                >
                  <Typography variant='h2'>Reminder</Typography>
                  <Typography variant="body2" color={colors.black800}>
                    Set up a task with a due date that can be checked off when completed.
                  </Typography> 
                </CabButton>
                <Divider sx={{backgroundColor: colors.black200}}/>
                <CabButton 
                  buttonType="text"
                  onClick={() => setShowNoteModal(true)}
                  sx={{ width: '100%', '& .button-text': { width: '100%', color: colors.black900, textAlign: 'left' } }}
                >
                  <Typography variant='h2' >Note</Typography>
                  <Typography variant="body2" color={colors.black800}>
                    Jot down a note for this contact
                  </Typography> 
                </CabButton>
              </Box>
            }
            popOverTitle=""
          />

        </Box>
      }
    >
      {contact ? (
        <Box display="flex" height="100%" width="100%" gap={3} padding={3} flexDirection="column">
          <Box display="flex" flex={1} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <Box display="flex" gap={1} padding={2} paddingLeft={1}>
                  <CabAvatar name={contact?.name || ''} color={colors.black500} />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h1">{contact?.name || contact.primary_email}</Typography>
                    <Typography>{contact.company?.name || ''}</Typography>
                  </Box>
                </Box>
              </Widget>
            </Box>
            <Box display="flex" flex={3} flexDirection="row" justifyContent="flex-end" gap={1} alignItems="flex-start">
              <CabButton
                onClick={() => setShowRelationshipsModal(true)}
                buttonType="text"
                sx={{ height: 26, textDecoration: 'underline' }}
              >
                View Relationships
              </CabButton>
              <AvatarGroup max={4} spacing={15}>
                {relationshipLeaders.map(leader => (
                  <CabAvatar
                    key={leader.id}
                    src={getLeaderIconSrc(leader)}
                    color={leader.color}
                    name={`${leader.first_name}
                    ${leader.last_name}`}
                    size="intermediate"
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Box>
          <Box display="flex" flex={12} gap={3} flexDirection={{ xs: 'column', md: 'row' }}>
            <Box display="flex" flex={2}>
              <Widget>
                <BasicInfoView
                  contact={contact}
                  onSave={onSaveBasicInfo}
                  orgOptions={orgOptions}
                  categoryOptions={categoryOptions}
                  typeOptions={typeOptions}
                  fieldTemplates={fieldTemplates}
                  onSetOrgString={onSetOrgString}
                  contactOrgPage={contactOrgPage}
                />
              </Widget>
            </Box>
            <Box display="flex" flex={3} flexDirection="column" gap={3} overflow="hidden">
              <Box display="flex" flex={3}>
                <Widget>
                  <CabTabs
                    tabs={tabs} align="left" hideDivider
                    sx={{ height: '100%' }}
                    tabPanelSx={{ paddingTop: 2, height: '100%' }}
                  >
                    <ActivitiesView
                      activities={activities}
                      onDeleteActivity={handleDeleteActivity}
                      onUpdateActivity={onUpdateTask}
                      onClickActivity={handleClickActivity}
                    />
                    <RemindersView
                      reminders={tasks}
                      onDeleteTask={onDeleteTask}
                      onUpdateTask={onUpdateTask}
                      onClickTask={handleClickTask}
                      onAddTask={() => setShowTaskModal(true)}
                    />
                    <NotesView
                      notes={notes}
                      onDeleteNote={onDeleteNote}
                      onClickNote={handleClickNote}
                      onAddNote={() => setShowNoteModal(true)}
                    />
                    {loadingEvents ? (
                      <Box height='100%' display='flex' alignItems='center'>
                        <CabSpinner scale={4} color='inherit' />
                      </Box>
                    ) : (
                      <MeetingsView events={events}/>
                    )}
                  </CabTabs>
                </Widget>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box hidden={loading} display='flex' justifyContent='center' marginTop={5}>
          <Alert severity="warning">
            Contact not found
          </Alert>
        </Box>
      )}
      <Box hidden={!loading} height='100%' display='flex' alignItems='center'>
        <CabSpinner scale={4} color='inherit' />
      </Box>

      {showNoteModal && (
        <AddNoteModal
          open={showNoteModal}
          onClose={handleAddEditNoteCancel}
          onSubmit={handleAddEditNote}
          note={editNote != null ? notes.find(n => n.id === editNote) : undefined}
        />
      )}

      {showTaskModal && (
        <AddTaskModal
          open={showTaskModal}
          onClose={handleAddEditTaskCancel}
          onSubmit={handleAddEditTask}
          task={editTask != null ? tasks.find(t => t.id === editTask) : undefined}
        />
      )}

      {showRelationshipsModal && (
        <EditRelationshipsModal
          open={showRelationshipsModal}
          onClose={() => setShowRelationshipsModal(false)}
          onSubmit={handleUpdateRelationships}
          leaderOptions={leaderOptions}
          leaderRelationships={relationshipLeaders}
        />
      )}

    </CabinetPage>
  );
};

interface BasicInfoForm {
  name: string;
  email: string;
  company: number | null;
  title: string;
  contactType: number | null;
  contactCategory: number | null;
  templateFields: Record<string, { textValue: string | null; choices: number[] | null }>;
}

const BasicInfoView = ({
  contact, orgOptions, categoryOptions, typeOptions, fieldTemplates, onSetOrgString, contactOrgPage,
  onSave
}: {
  contact: ContactDenorm,
  onSave: (info: Partial<Contact>) => void;
  orgOptions: ContactOrgDenorm[]; categoryOptions: ContactCategory[]; typeOptions: ContactType[];
  fieldTemplates: ContactFieldTemplate[];
  onSetOrgString: (val: string) => void;
  contactOrgPage?: string;
}) => {
  const { control, handleSubmit, reset, formState } = useForm<BasicInfoForm>({
    values: {
      name: contact.name || '', email: contact.primary_email || '', company: contact.company?.id || null,
      title: contact.title || '', contactType: contact.contact_type?.id || null,
      contactCategory: contact.contact_category?.id || null,
      templateFields: Object.fromEntries(fieldTemplates.map(template => {
        const field = contact.fields?.[template.id];
        return [template.id, {
          textValue: field?.text_value || null,
          choices: field?.choices || null,
        }];
      })) as Record<number, { textValue: string | null; choices: number[] | null }>,
    }
  });

  const handleSubmitForm = (values: BasicInfoForm) => {
    const { contactType, contactCategory, templateFields, ...others } = values;
    onSave({
      ...others,
      contact_type: contactType,
      contact_category: contactCategory,
      fields: Object.fromEntries(Object.entries(templateFields).map(([templateId, val]) => ([
        templateId, {
          text_value: val.textValue,
          choices: val.choices,
        }
      ])))
    });
  };

  return (
    <WidgetList>
      {formState.isDirty && (
        <Box display="flex" width="100%" marginTop={2} gap={2}>
          <CabButton
            size="small"
            onClick={() => reset()}
            buttonType="tertiary"
            sx={{ flex: 1}}
          >
            Discard Changes
          </CabButton>
          <CabButton
            size="small"
            onClick={handleSubmit(handleSubmitForm)}
            sx={{ flex: 1}}
          >
            Save Fields
          </CabButton>
        </Box>
      )}

      <Controller name="name" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="text" title="Name" inputValue={field.value}
          text={field.value}
          isDirty={fieldState.isDirty}
          onSave={field.onChange}
        />
      )} />
      <Controller name="email" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="text" title="Email" inputValue={field.value}
          text={field.value}
          isDirty={fieldState.isDirty}
          onSave={field.onChange}
        />
      )} />
      <Controller name="company" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="autocomplete"
          title="Company"
          selectOptions={orgOptions.map(o => ({ value: o.id, label: o.name }))}
          inputValue={field.value}
          text={orgOptions.find(o => o.id === field.value)?.name}
          onSave={field.onChange}
          onInputChange={onSetOrgString} placeholder="Type to search all companies"
          noOptionsText="No companies match this search"
          isDirty={fieldState.isDirty}
          link={contactOrgPage}
        />
      )} />
      <Controller name="title" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="text" title="Title"
          inputValue={field.value}
          text={field.value}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
      <Controller name="contactType" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="select"
          selectOptions={[
            { value: '', label: 'Unassigned', color: colors.black300 },
            ...typeOptions.map(o => ({ value: o.id, label: o.name, color: o.color }))
          ]}
          inputValue={field.value}
          title="Internal/External"
          text={typeOptions.find(o => o.id === field.value)?.name || 'Unassigned'}
          color={typeOptions.find(o => o.id === field.value)?.color || colors.black300}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />
      <Controller name="contactCategory" control={control} render={({ field: { ref, ...field }, fieldState }) => (
        <BasicInfoListItem inputType="select"
          selectOptions={[
            { value: '', label: 'Unassigned', color: colors.black300 },
            ...categoryOptions.map(o => ({ value: o.id, label: o.name, color: o.color }))
          ]}
          inputValue={field.value}
          title="Category"
          text={categoryOptions.find(o => o.id === field.value)?.name || 'Unassigned'}
          color={categoryOptions.find(o => o.id === field.value)?.color || colors.black300}
          onSave={field.onChange}
          isDirty={fieldState.isDirty}
        />
      )} />

      {fieldTemplates.map(template => {
        return (
          <Controller key={template.id} name={`templateFields.${template.id}`} control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <BasicInfoListItem
                inputType={['single_select', 'multi_select'].includes(template.value_type) ? "select"
                  // TODO: make date picker
                  : template.value_type === 'date_type' ? 'text'
                    : 'text'
                }
                selectOptions={template.contact_field_choices.map((o, idx) => ({ value: idx, label: o.label }))}
                inputValue={template.value_type === 'single_select' ? (field.value?.choices?.[0] ?? '')
                  : (field.value?.choices || field.value?.textValue || null)}
                title={template.label}
                text={field.value?.choices
                  ? field.value.choices.map(c => template.contact_field_choices[c].label).join(', ')
                  : field.value?.textValue
                  || ''}
                onSave={(val) => {
                  const v: { textValue?: string; choices?: number[] } = {};
                  if (['single_select', 'multi_select'].includes(template.value_type)) {
                    v.choices = Array.isArray(val) ? val : typeof val === 'number' ? [val] : [];
                  } else {
                    v.textValue = val?.toString();
                  }
                  field.onChange(v);
                }}
                isDirty={fieldState.isDirty}
              />
            )}
          />
        );
      })}
    </WidgetList>
  );
};

const ActivitiesView = ({ activities, onDeleteActivity, onUpdateActivity, onClickActivity }: {
  activities: (ContactTask | ContactNote | AnalyticsEvent)[],
  onDeleteActivity: (id: ContactTask['id'] | ContactNote['id'], activityType: 'task'|'note') => void;
  onUpdateActivity: Trigger<AppCrmContactTasksPartialUpdateApiArg, ContactTask>;
  onClickActivity: (id: ContactTask['id'], activityType: 'task'|'note') => void;
}) => (
  activities.length ? (
    <WidgetList>
      {activities.map(r => (
        'assignee' in r ? (
          <ReminderListItem
            key={r.id}
            title={r.title}
            dateAdded={r.created_at}
            checked={r.status === 'Completed'}
            onClick={() => onClickActivity(r.id, 'task')}
            onCheck={(check) => onUpdateActivity({
              id: r.id,
              patchedContactTask: { status: check ? 'Completed' : 'To-Do' },
            })}
            onDelete={() => onDeleteActivity(r.id, 'task')}
          />
        ) : 'body' in r ? (
          <NoteListItem
            key={r.id}
            title={r.body_plain_text || 'UNKNOWN'}
            text={r.created_at && DateTime.fromISO(r.created_at).toLocaleString(DateTime.DATETIME_FULL)}
            onClick={() => onClickActivity(r.id, 'note')}
            onDelete={() => onDeleteActivity(r.id, 'note')}
          />
        ) : 'attendees' in r ? (
          <MeetingListItem 
            key={r.id} 
            title={r.title} 
            startDate={r.start_date}
            attendeeCount={r.attendees.length}
          />
        ) : null
      ))}
    </WidgetList>
  ) : (
    <Box
      display="flex" flexDirection="column" width="100%" height="82%"
      justifyContent="center" alignItems="center" gap={2}
    >
      <Typography color={colors.black800}>There are no activities for this contact</Typography>
    </Box>
  )
);


const RemindersView = ({ reminders, onDeleteTask, onUpdateTask, onClickTask, onAddTask }: {
  reminders: ContactTask[], onDeleteTask: Trigger<ContactTask['id'], unknown>;
  onUpdateTask: Trigger<AppCrmContactTasksPartialUpdateApiArg, ContactTask>;
  onClickTask: (id: ContactTask['id']) => void;
  onAddTask: () => void;
}) => (
  reminders.length ? (
    <WidgetList>
      {reminders.map(r => (
        <ReminderListItem
          key={r.id}
          title={r.title}
          checked={r.status === 'Completed'}
          onClick={() => onClickTask(r.id)}
          onCheck={(check) => onUpdateTask({ id: r.id, patchedContactTask: { status: check ? 'Completed' : 'To-Do' } })}
          onDelete={() => onDeleteTask(r.id)}
        />
      ))}
    </WidgetList>
  ) : (
    <Box
      display="flex" flexDirection="column" width="100%" height="82%"
      justifyContent="center" alignItems="center" gap={2}
    >
      <Typography color={colors.black800}>There are no reminders for this contact</Typography>
      <CabButton buttonType="tertiary" onClick={onAddTask}>Add Reminder</CabButton>
    </Box>
  )
);

const MeetingsView = ({ events }: { events: AnalyticsEvent[] }) => (
  events && events.length ? (
    <WidgetList>
      {events.map(e => (
        <MeetingListItem 
          key={e.id} 
          title={e.title} 
          startDate={e.start_date}
          attendeeCount={e.attendees.length}
        />
      ))}
    </WidgetList>
  ) : (
    <>No Events for contact</>
  )
);

const NotesView = ({ notes, onClickNote, onDeleteNote, onAddNote }: {
  notes: ContactNote[], onDeleteNote: Trigger<ContactNote['id'], unknown>;
  onClickNote: (id: ContactNote['id']) => void;
  onAddNote: () => void;
}) => (
  notes.length ? (
    <WidgetList>
      {notes.map(n => (
        <NoteListItem
          key={n.id}
          title={n.body_plain_text || 'UNKNOWN'}
          text={n.created_at && DateTime.fromISO(n.created_at).toLocaleString(DateTime.DATETIME_FULL)}
          onClick={() => onClickNote(n.id)}
          onDelete={() => onDeleteNote(n.id)}
        />
      ))}
    </WidgetList>
  ) : (
    <Box
      display="flex" flexDirection="column" width="100%" height="82%"
      justifyContent="center" alignItems="center" gap={2}
    >
      <Typography color={colors.black800}>There are no notes for this contact</Typography>
      <CabButton buttonType="tertiary" onClick={onAddNote}>Add Note</CabButton>
    </Box>
  )
);

const ReminderListItem = ({ title, text, checked, onEdit, onCheck, onDelete, onClick, dateAdded }: {
  title: string; text?: string | null; checked?: boolean; onEdit?: () => void;
  onCheck: (check: boolean) => void; onDelete: () => void; onClick: () => void;
  dateAdded?: string;
}) => (
  <WidgetListItem secondaryAction={
    <CabIcon Icon={IoClose} onClick={onDelete} />
  }>
    <ListItemIcon sx={{ minWidth: 35 }}>
      <CabCheckbox checked={checked} onChange={(e, c) => onCheck(c)} />
    </ListItemIcon>
    <ListItemText
      primary={<Link href="#" color={colors.black900} underline="hover" onClick={onClick}>{title}</Link>}
      secondary={dateAdded && `Added on ${DateTime.fromISO(dateAdded).toLocaleString(DateTime.DATETIME_FULL)}`}
      primaryTypographyProps={{ sx: { textDecoration: checked ? 'line-through' : undefined } }}
    />
  </WidgetListItem>
);

const NoteListItem = ({ title, text, onDelete, onClick }: {
  title: string; text?: string; onDelete: () => void; onClick: () => void;
}) => (
  <WidgetListItem secondaryAction={
    <CabIcon Icon={IoClose} onClick={onDelete} />
  }>
    <ListItemText
      primary={<Link href="#" color={colors.black900} underline="hover" onClick={onClick}>{title}</Link>}
      secondary={text}
      primaryTypographyProps={{ noWrap: true }}
    />
  </WidgetListItem>
);

const MeetingListItem = ({ title, startDate, attendeeCount, onEdit }: {
  title: string, startDate: string, attendeeCount: number, onEdit?: () => void
}) => (
  <WidgetListItem>
    <ListItemText
      primary={title}
      secondary={
        <Box display='flex' gap={1} marginTop={.5}>
          <Box display='flex' gap={.25}>
            <CabIcon Icon={IoCalendarOutline} sx={{fontSize: 14, color: colors.black800}} />
            <Typography variant='body2' lineHeight={'16px'} sx={{color: colors.black800}}>
              {`${DateTime.fromISO(startDate).toLocaleString(DateTime.DATETIME_FULL)}`}
            </Typography>
          </Box>
          <Box display='flex' gap={.25}>
            <CabIcon Icon={IoPeopleOutline} sx={{fontSize: 14, color: colors.black800}} />
            <Typography variant='body2' lineHeight={'16px'} sx={{color: colors.black800}}>
              {`${attendeeCount} attendees`}
            </Typography>
          </Box>
        </Box>
      }
    />
  </WidgetListItem>
);

const AddNoteModal = ({ open, onClose, onSubmit, note }: {
  open: boolean; onClose: () => void; onSubmit: ({ body} : { body: string }) => void;
  note?: ContactNote,
}) => {
  const { control, handleSubmit, formState } = useForm<{ body: string }>({ defaultValues: {
    body: note?.body && isRT(note.body) ? note.body : EMPTY_STATE,
  } });

  return (
    <CabModal open={open} title={`${note ? 'Edit' : 'New'} Note`}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {note ? 'Save' : 'Add'}
        </CabButton>
      </>}
    >
      <Box height={200}>
        <Controller name="body" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabRTEditor
              placeholder="Type to add notes..."
              {...field}
              editorState={field.value}
            />
          )}
        />

      </Box>
    </CabModal>
  );
};

const AddTaskModal = ({ open, onClose, onSubmit, task }: {
  open: boolean; onClose: () => void; onSubmit: ({ title } : { title: string }) => void;
  task?: ContactTask,
}) => {
  const { control, handleSubmit, formState } = useForm<{ title: string }>({ defaultValues: {
    title: task?.title || '',
  } });

  return (
    <CabModal open={open} title={`${task ? 'Edit' : 'New'} Reminder`}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {task ? 'Save' : 'Add'}
        </CabButton>
      </>}
    >
      <Box height={200}>
        <FormController name="title" label="Task" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabTextInput inputRef={ref} {...field} multiline minRows={7} />
          )}
        />

      </Box>
    </CabModal>
  );
};

const EditRelationshipsModal = ({ open, onClose, onSubmit, leaderOptions, leaderRelationships }: {
  open: boolean; onClose:() => void;
  onSubmit: ({ leaderRelationships }: { leaderRelationships: Pick<Leader, 'id'>[] }) => void;
  leaderOptions: Leader[], leaderRelationships: Leader[],
}) => {
  const { control, handleSubmit, formState, watch } = useForm<{ leaderRelationships: Pick<Leader, 'id'>[] }>({
    defaultValues: { leaderRelationships }
  });

  const { fields: relationshipFields, append, remove } = useFieldArray({
    control, name: 'leaderRelationships',
    // need to specify this or it will override the leader `id`
    keyName: 'fieldId'
  });

  const leaderRelValues = watch('leaderRelationships');
  const lastFieldId = leaderRelValues.at(-1)?.id;

  const cabExecOptions = useMemo(() => leaderOptions.map(leader => {
    return {
      value: leader.id,
      label: `${leader.first_name} ${leader.last_name}`,
      icon: <CabAvatar
        src={getLeaderIconSrc(leader)}
        color={leader.color}
        name={`${leader.first_name}
        ${leader.last_name}`}
        size="small"
      />
    };
  }), [leaderOptions]);

  const availableOptions = cabExecOptions.filter(o => !leaderRelValues.find(f => f.id === o.value));

  return (
    <CabModal open={open} title="Relationships"
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid || formState.isSubmitting}>
          {formState.isSubmitting ? <CabSpinner scale={1} /> : 'Save'}
        </CabButton>
      </>}
    >
      <Box display="flex" flexDirection="column">
        {relationshipFields.map((relField, index) => (
          <FormController key={relField.fieldId} name={`leaderRelationships.${index}.id`} label=""
            control={control} rules={{ required: true, validate: (v) => v !== -1 }}
            render={({ field: { ref, ...field }}) => {
              const selectedOption = cabExecOptions.find(o => o.value === field.value);
              const options = selectedOption
                ? [selectedOption, ...availableOptions.filter(ao => ao.value !== selectedOption.value)]
                : availableOptions;

              return (
                <Box display="flex" gap={1}>
                  <CabExecPicker
                    {...field}
                    value={field.value === -1 ? '' : field.value}
                    options={options}
                    placeholder="Select a Teammate"
                    fullWidth
                  />
                  <CabIcon Icon={IoCloseCircleOutline} onClick={() => remove(index)} sx={{ marginLeft: 2 }} />
                </Box>
              );
            }}
          />
        ))}
        {availableOptions.length > 0 && (
          <CabButton
            onClick={() => append({ id: -1 })}
            icon={<CabIcon Icon={IoAdd} />}
            buttonType="tertiary"
            sx={{ width: '100%', marginTop: 2 }}
            disabled={lastFieldId === -1}
          >
            Add New Relationship
          </CabButton>
        )}
      </Box>
    </CabModal>
  );
};


export default CRMContactView;
